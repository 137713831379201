import React, { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import AthleteDashboard from "./AthleteDashboard";
import CoachDashboard from "./CoachDashboard";
import Header from "../../Util/Header";

const Dashboard = () => {
    const [userData, setUserData] = useState({
        _id: null,
        name: '',
        email : '',
        role : '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('/api/self', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Include http-only cookies if needed
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.role === "coach" || (data.setupDone && data.exerciseSelectionDone && data.scheduleDone)){
                        setUserData(data);
                    } else {
                        navigate("/settings")
                    }
                } else {
                    navigate("/login");
                }
            } catch (error) {
                console.error('Error during API call:', error);
            }
        };

        fetchUserData();
    }, [navigate]); // Empty dependency array ensures the effect runs only once on mount

    return (
        <div className={"bg-gray-100 min-h-screen"}>
            <Header/>
            {userData._id !== null ? (
                userData.role === 'athlete'? <AthleteDashboard currentPlan={userData.currentPlan}/> : <CoachDashboard/>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default Dashboard;