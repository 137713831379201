import React, {useEffect, useState} from 'react';
import SetupComponent from './SetupComponent';
import ScheduleComponent from './ScheduleComponent';
import ExercisesComponent from './ExercisesComponent';
import Header from "../../Util/Header";
import {useNavigate} from "react-router-dom";

const SettingsPage = () => {
    const [activeStep, setActiveStep] = useState('Schedule');
    const [user, setUser] = useState(undefined);
    const [plan, setPlan] = useState(undefined);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let response = await fetch('/api/self', {credentials: "include"});
            const user = await response.json();

            if (user.role === "coach") {
                navigate("/");
                return;
            }
            setUser(user);

            response = await fetch('/api/settings', {credentials: "include"});
            const plan = await response.json();
            setPlan(plan);


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (user) {
            if (!user.exerciseSelectionDone) {
                setActiveStep('Exercises');
            }
            if (!user.setupDone) {
                setActiveStep('Setup');
            }
            if (!user.scheduleDone) {
                setActiveStep("Schedule")
            }

        }
    }, [user]);

    const renderStep = () => {
        switch (activeStep) {
            case 'Setup':
                return <SetupComponent user={user} setUser={setUser}/>;
            case 'Schedule':
                return <ScheduleComponent user={user} setUser={setUser} plan={plan}/>;
            case 'Exercises':
                return <ExercisesComponent user={user} setUser={setUser} plan={plan}/>;
            default:
                return null;
        }
    };

    if (!user || !plan) {
        return <div>loading</div>
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <Header/>
            <div >
                {/* Step Button Menu */}
                <div className="bg-secondary p-4 flex w-full justify-evenly">
                    <button
                        className={`rounded-full p-2 ${
                            activeStep === 'Schedule' ? 'underline' : ''
                        } ${user.scheduleDone ? 'text-accent' : 'text-red-600'}`}
                        onClick={() => setActiveStep('Schedule')}
                    >
                        Trainingsplan
                    </button>
                    <button
                        className={`rounded-full p-2 ${
                            activeStep === 'Setup' ? 'underline' : ''
                        } ${user.setupDone ? 'text-accent' : 'text-red-600'}`}
                        onClick={() => setActiveStep('Setup')}
                    >
                        Setup
                    </button>
                    <button
                        className={`rounded-full p-2 ${
                            activeStep === 'Exercises' ? 'underline' : ''
                        } ${user.exerciseSelectionDone ? 'text-accent' : 'text-red-600'}`}
                        onClick={() => setActiveStep('Exercises')}
                    >
                        Übungen
                    </button>

                </div>
                {/* Render Step */}
                {renderStep()}
            </div>

        </div>

    );
};

export default SettingsPage;
