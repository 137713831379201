// SetupComponent.jsx

import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

const SetupComponent = ({user, setUser}) => {
    const [formData, setFormData] = useState(user.age ? {
        age: user.age,
        gender: user.gender,
        weight: user.weight,
        height: user.height,
        position: user.position,
        sleepHours: user.sleepHours,
    } : {
        age : "",
        gender : "",
        weight : "",
        height: "",
        position : "",
        sleepHours: "",
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;

        // Validate number fields
        if ((name === 'age' || name === 'weight' || name === 'height' || name === 'sleepHours') && isNaN(value)) {
            setErrors({...errors, [name]: 'Bitte eine Zahl eingeben'});
        } else {
            setErrors({...errors, [name]: ''});
        }

        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Simple form validation
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = 'Eingabe erforderlich';
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch('/api/settings/setup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials : 'include'
            });

            if (response.ok) {
                const user = await response.json();
                if (user.exerciseSelectionDone && user.setupDone && user.scheduleDone) {
                    navigate("/");
                    return;
                }
                setUser(user);
            } else {
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error during form submission:', error);
        }
    };

    return (
            <div className="m-4">
                <h1 className="text-3xl font-bold text-secondary mb-5">Setup</h1>
                <form onSubmit={handleSubmit}>
                    {/* Render your form inputs and dropdowns here */}
                    {/* Example: */}
                    <input
                        type="number"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        placeholder="Alter (Jahre)"
                        className={`block w-full p-3 mt-2 rounded-md border  ${
                            errors.age ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    />
                    {errors.age && <p className="text-red-500 text-xs">{errors.age}</p>}

                    <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className={`block w-full p-5 mt-2 rounded-md border ${
                            errors.gender ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    >
                        <option value="" disabled>Geschlecht wählen</option>
                        <option value="male">Männlich</option>
                        <option value="female">Weiblich</option>
                        <option value="diverse">Divers</option>
                    </select>
                    {errors.gender && <p className="text-red-500 text-xs">{errors.gender}</p>}

                    <input
                        type="number"
                        name="weight"
                        value={formData.weight}
                        onChange={handleChange}
                        placeholder="Gewicht (kg)"
                        className={`block w-full p-3 mt-2 rounded-md border  ${
                            errors.weight ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    />
                    {errors.weight && <p className="text-red-500 text-xs">{errors.weight}</p>}

                    <input
                        type="number"
                        name="height"
                        value={formData.height}
                        onChange={handleChange}
                        placeholder="Größe (cm)"
                        className={`block w-full p-3 mt-2 rounded-md border ${
                            errors.height ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    />
                    {errors.height && <p className="text-red-500 text-xs">{errors.height}</p>}

                    <select
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                        className={`block w-full p-3 mt-2 rounded-md border  ${
                            errors.position ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    >
                        <option value="" disabled>Position wählen</option>
                        <option value="GK">Torwart</option>
                        <option value="CB">Innenverteidiger</option>
                        <option value="LB">Linksverteidiger</option>
                        <option value="RB">Rechtsverteidiger</option>
                        <option value="LWB">Linker Flügelverteidiger</option>
                        <option value="RWB">Rechter Flügelverteidiger</option>
                        <option value="CDM">Defensiver Mittelfeldspieler</option>
                        <option value="CM">Zentraler Mittelfeldspieler</option>
                        <option value="ACM">Offensiver Mittelfeldspieler</option>
                        <option value="LW">Linker Flügelstürmer</option>
                        <option value="RW">Rechter Flügelstürmer</option>
                        <option value="ST">Stürmer</option>
                    </select>
                    {errors.position && <p className="text-red-500 text-xs">{errors.position}</p>}

                    <input
                        type="number"
                        step="0.5"
                        name="sleepHours"
                        value={formData.sleepHours}
                        onChange={handleChange}
                        placeholder="Durchschnittliche Schlafdauer (Stunden)"
                        className={`block w-full p-3 mt-2 rounded-md border h-10 ${
                            errors.sleepHours ? 'border-red-500' : 'border-gray-300'
                        } focus:outline-none focus:border-accent focus:ring focus:ring-accent-light`}
                    />
                    {errors.sleepHours && <p className="text-red-500 text-xs">{errors.sleepHours}</p>}

                    {!user.setupDone &&  <button
                        type="submit"
                        className="w-full bg-accent text-dark py-3 mt-3 rounded-md">
                        Speichern
                    </button>}
                </form>
            </div>
    );
};

export default SetupComponent;
