import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../../Util/Header';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

const WorkoutPage = () => {
    const [workout, setWorkout] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWorkout = async () => {
            try {
                const response = await fetch(`/api/workouts/${id}`, {
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch workout');
                }
                const data = await response.json();
                setWorkout(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchWorkout();
    }, [id]);

    const showExercise = async (event) => {
        navigate("/workoutexercises/" + event.target.id)
    }

    const isExerciseDone = (exercise) => {
        return exercise.actualReps.length === Math.round(exercise.sets) ;

    }

    function isQuestionnaireDone(questionnaire) {
        return !questionnaire.questions.some(question => question.value === -6);
    }

    async function showQuestionnaire(event) {
        navigate("/workoutquestionnaires/" + event.target.id)
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header primaryColor="primary" secondaryColor="secondary" />
            <div className={"bg-dark rounded-md p-2 m-2 text-accent max-w-full py-3"} onClick={e =>  navigate(-1)}><span className={"text-accent mr-2"}><FontAwesomeIcon icon={faChevronLeft} /> Zurück zur Trainings-Übersicht</span></div>

            <div className="m-4">
                {workout ? (
                    <div className="">
                        <h1 className="text-2xl font-bold mb-4 text-secondary">{workout.type}</h1>
                        <p>Datum: {(new Date(workout.date)).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}</p>
                        <div className="mt-4">
                            <ul className={"mb-5"}>
                                {workout.questionnairePre.map((questionnaire) => (
                                    <li key={questionnaire._id} id={questionnaire._id} className={`text-lg text-accent rounded-md my-2 p-5 ${isQuestionnaireDone(questionnaire) ? "bg-primary" : "bg-secondary"}`} onClick={showQuestionnaire}>{questionnaire.name}</li>
                                ))}
                            </ul>
                            <ul className={"mb-5"}>
                                {workout.exercises.map((exercise) => (
                                    <li key={exercise._id} id={exercise._id} className={`text-lg text-accent rounded-md my-2 p-5 ${isExerciseDone(exercise) ? "bg-primary" : "bg-secondary"}`} onClick={showExercise}>{exercise.name}</li>
                                ))}
                            </ul>
                            <ul>
                                {workout.questionnairePost.map((questionnaire) => (
                                    <li key={questionnaire._id} id={questionnaire._id} className={`text-lg text-accent rounded-md my-2 p-5 ${isQuestionnaireDone(questionnaire) ? "bg-primary" : "bg-secondary"}`} onClick={showQuestionnaire}>{questionnaire.name}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default WorkoutPage;
