import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

const ExercisesComponent = ({user, setUser, plan}) => {
    const [exercises, setExercises] = useState(user.selectedExercises ? user.selectedExercises : {
        tp1: {
            selected: "",
        },
        tp2: {
            selected: "",
        },
        romanianDeadlift: {
            selected: "Romanian Deadlift",
            weight: "",
            reps: ""
        },
       /* legCurl: {
            selected: "",
            weight: "",
            reps: ""
        },*/
        barbellSquat: {
            selected: "Barbell Backsquat",
            weight: "",
            reps: ""
        },
        lunges: {
            selected: "Lunges",
            weight: "",
            reps: ""
        },
        jumps: {
            selected: "",
        },
        pushHorizontal: {
            selected: "",
            weight: "",
            reps: ""
        },
        pushVertical: {
            selected: "",
            weight: "",
            reps: ""
        },
        pullHorizontal: {
            selected: "",
            weight: "",
            reps: ""
        },
        pullVertical: {
            selected: "",
            weight: "",
            reps: ""
        },
        paloffPress: {
            selected: "Pallof Press",
            weight: "",
            reps: ""
        },
        hangingKneeRaises: {
            selected: "Hanging Knee Raises",
        }
    });
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");
    const validateExercises = () => {
        for (const key in exercises) {
            const exercise = exercises[key];
            if (typeof exercise === 'object') {
                if (exercise.selected === "") {
                    setErrorMessage("Bitte Variante auswählen: " + key)
                    return false;
                }
                if ("weight" in exercise && (exercise.weight === "" || parseFloat(exercise.weight) <= 0)) {
                    setErrorMessage("Bitte valides Gewicht angeben: " + exercise.selected)
                    return false;
                }
                if ("reps" in exercise && (exercise.reps === "" || parseInt(exercise.reps) <= 0)) {
                    setErrorMessage("Bitte valide Reps angeben: " + exercise.selected)
                    return false;
                }
            }
        }
        return true;
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (!validateExercises()) {
            return;
        }

        try {
            const response = await fetch('/api/settings/exercises', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(exercises),
            });
            if (!response.ok) {
                throw new Error('Failed to save exercises');
            }
            let user = await response.json();
            if (user.exerciseSelectionDone && user.setupDone && user.scheduleDone) {
                navigate("/");
                return;
            }
            setErrorMessage('');
            setUser(user);
        } catch (error) {
            console.error(error.message);
            setErrorMessage('Failed to save exercises');
        }
    };

    const handleSelectionChange = (id, value) => {
        setExercises(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                selected: value
            }
        }));
    };

    const handleWeightChange = (id, value) => {
        setExercises(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                weight: value
            }
        }));
    };

    const handleRepChange = (id, value) => {
        setExercises(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                reps: value
            }
        }));
    };

    return (
        <div className="m-4">

            <h1 className="text-3xl font-bold text-secondary mb-5">Übungen</h1>
            <p className={"text-secondary"}>Eine Übersicht und Erklärung aller Übungen findest du <Link to={"/allexercises"} target={"_blank"}
                className={"underline"}>hier</Link></p>
            <p className={"text-secondary"}>Bitte bei Übungen mit Gewicht angeben, wie viele Wiederholungen du bei
                welchem Gewicht schaffst.</p>

            <ExerciseSelection
                id={"tp1"}
                name={"ITP1"}
                options={plan.exercises.tp1}
                selected={exercises.tp1.selected}
                onSelectionChange={(value) => handleSelectionChange("tp1", value)}
                hasWeights={false}
            />

            <ExerciseSelection
                id={"tp2"}
                name={"ITP2"}
                options={plan.exercises.tp2}
                selected={exercises.tp2.selected}
                onSelectionChange={(value) => handleSelectionChange("tp2", value)}
                hasWeights={false}
            />

            <ExerciseSelection
                id={"romanianDeadlift"}
                name={"Romanian Deadlift"}
                options={plan.exercises.romanianDeadlift}
                selected={exercises.romanianDeadlift.selected}
                onSelectionChange={(value) => handleSelectionChange("romanianDeadlift", value)}
                hasWeights={true}
                weight={exercises.romanianDeadlift.weight}
                reps={exercises.romanianDeadlift.reps}
                onRepChange={(value) => handleRepChange("romanianDeadlift", value)}
                onWeightChange={(value) => handleWeightChange("romanianDeadlift", value)}
            />

            {/*<ExerciseSelection
                id={"legCurl"}
                name={"Leg Curl"}
                options={plan.exercises.legCurl}
                selected={exercises.legCurl.selected}
                onSelectionChange={(value) => handleSelectionChange("legCurl", value)}
                hasWeights={true}
                weight={exercises.legCurl.weight}
                reps={exercises.legCurl.reps}
                onRepChange={(value) => handleRepChange("legCurl", value)}
                onWeightChange={(value) => handleWeightChange("legCurl", value)}
            /> */}

            <ExerciseSelection
                id={"barbellSquat"}
                name={"Barbell Squat"}
                options={plan.exercises.barbellSquat}
                selected={exercises.barbellSquat.selected}
                onSelectionChange={(value) => handleSelectionChange("barbellSquat", value)}
                hasWeights={true}
                weight={exercises.barbellSquat.weight}
                reps={exercises.barbellSquat.reps}
                onRepChange={(value) => handleRepChange("barbellSquat", value)}
                onWeightChange={(value) => handleWeightChange("barbellSquat", value)}
            />

            <ExerciseSelection
                id={"lunges"}
                name={"Lunges"}
                options={plan.exercises.lunges}
                selected={exercises.lunges.selected}
                onSelectionChange={(value) => handleSelectionChange("lunges", value)}
                hasWeights={true}
                weight={exercises.lunges.weight}
                reps={exercises.lunges.reps}
                onRepChange={(value) => handleRepChange("lunges", value)}
                onWeightChange={(value) => handleWeightChange("lunges", value)}
            />

            <ExerciseSelection
                id={"jumps"}
                name={"Jumps"}
                options={plan.exercises.jumps}
                selected={exercises.jumps.selected}
                onSelectionChange={(value) => handleSelectionChange("jumps", value)}
                hasWeights={false}
            />

            <ExerciseSelection
                id={"pushHorizontal"}
                name={"Push Horizontal"}
                options={plan.exercises.pushHorizontal}
                selected={exercises.pushHorizontal.selected}
                onSelectionChange={(value) => handleSelectionChange("pushHorizontal", value)}
                hasWeights={true}
                weight={exercises.pushHorizontal.weight}
                reps={exercises.pushHorizontal.reps}
                onRepChange={(value) => handleRepChange("pushHorizontal", value)}
                onWeightChange={(value) => handleWeightChange("pushHorizontal", value)}
            />

            <ExerciseSelection
                id={"pushVertical"}
                name={"Push Vertical"}
                options={plan.exercises.pushVertical}
                selected={exercises.pushVertical.selected}
                onSelectionChange={(value) => handleSelectionChange("pushVertical", value)}
                hasWeights={true}
                weight={exercises.pushVertical.weight}
                reps={exercises.pushVertical.reps}
                onRepChange={(value) => handleRepChange("pushVertical", value)}
                onWeightChange={(value) => handleWeightChange("pushVertical", value)}
            />

            <ExerciseSelection
                id={"pullHorizontal"}
                name={"Pull Horizontal"}
                options={plan.exercises.pullHorizontal}
                selected={exercises.pullHorizontal.selected}
                onSelectionChange={(value) => handleSelectionChange("pullHorizontal", value)}
                hasWeights={true}
                weight={exercises.pullHorizontal.weight}
                reps={exercises.pullHorizontal.reps}
                onRepChange={(value) => handleRepChange("pullHorizontal", value)}
                onWeightChange={(value) => handleWeightChange("pullHorizontal", value)}
            />

            <ExerciseSelection
                id={"pullVertical"}
                name={"Pull Vertical"}
                options={plan.exercises.pullVertical}
                selected={exercises.pullVertical.selected}
                onSelectionChange={(value) => handleSelectionChange("pullVertical", value)}
                hasWeights={true}
                weight={exercises.pullVertical.weight}
                reps={exercises.pullVertical.reps}
                onRepChange={(value) => handleRepChange("pullVertical", value)}
                onWeightChange={(value) => handleWeightChange("pullVertical", value)}
            />

            <ExerciseSelection
                id={"paloffPress"}
                name={"Pallof Press"}
                options={plan.exercises.paloffPress}
                selected={exercises.paloffPress.selected}
                onSelectionChange={(value) => handleSelectionChange("paloffPress", value)}
                hasWeights={true}
                weight={exercises.paloffPress.weight}
                reps={exercises.paloffPress.reps}
                onRepChange={(value) => handleRepChange("paloffPress", value)}
                onWeightChange={(value) => handleWeightChange("paloffPress", value)}
            />

            <ExerciseSelection
                id={"hangingKneeRaises"}
                name={"Hanging Knee Raises"}
                options={plan.exercises.hangingKneeRaises}
                selected={exercises.hangingKneeRaises.selected}
                onSelectionChange={(value) => handleSelectionChange("hangingKneeRaises", value)}
                hasWeights={false}
            />


            <div className="text-red-500">{errorMessage}</div>
            {!user.exerciseSelectionDone && <button className="w-full bg-accent text-dark py-3 mt-5 rounded-md" onClick={handleSave}>
                Speichern
            </button>}

        </div>
    );
};

const ExerciseSelection = ({
                               id,
                               name,
                               options,
                               selected,
                               onSelectionChange,
                               hasWeights,
                               weight,
                               onWeightChange,
                               reps,
                               onRepChange
                           }) => {
    return (
        <div>
            <h2 className="text-lg font-semibold text-secondary mt-5">{name}</h2>
            <select
                id={id}
                value={selected}
                onChange={(e) => onSelectionChange(e.target.value)}
                className="block w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-accent focus:ring focus:ring-accent-light mr-2"
            >
                <option value="">Variante auswählen</option>
                {options.map(option => <option key={option} value={option}>{option}</option>)}
            </select>
            {hasWeights && (
                <div className="flex items-center mt-3">
                    <input type="number" placeholder="Gewicht" step="0.01" value={weight}
                           onChange={(e) => onWeightChange(e.target.value)}
                           className="w-20 p-1 rounded-md border border-gray-300 focus:outline-none focus:border-accent focus:ring focus:ring-accent-light"/>
                    <span className="text-secondary ml-1 mr-3">kg</span>

                    <input type="number" placeholder="Reps" value={reps}
                           onChange={(e) => onRepChange(e.target.value)}
                           className="w-20 p-1 rounded-md border border-gray-300 focus:outline-none focus:border-accent focus:ring focus:ring-accent-light"/>
                    <span className="text-secondary ml-1">reps</span>


                </div>
            )}

        </div>

    );
};

export default ExercisesComponent;
