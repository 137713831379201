
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import Dashboard from "./pages/DashboardPage/Dashboard";
import RenewPasswordPage from "./pages/LoginPage/RenewPasswordPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import WorkoutPage from "./pages/WorkoutPage/WorkoutPage";
import WorkoutexercisePage from "./pages/WorkoutexercisePage/WorkoutexercisePage";
import WorkoutQuestionnairePage from "./pages/WorkoutQuestionnairePage/WorkoutQuestionnairePage";
import AllExercisesPage from "./pages/AllExercisesPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path={"/"} element={<Dashboard/>}/>
          <Route exact path={"/login"} element={<LoginPage/>}/>
          <Route exact path={"/new-password"} element={<RenewPasswordPage/>}/>
          <Route exact path={"/settings"} element={<SettingsPage/>}/>
          <Route path="/workouts/:id" element={<WorkoutPage />} />
          <Route path="/workoutexercises/:id" element={<WorkoutexercisePage />} />
          <Route path="/workoutquestionnaires/:id" element={<WorkoutQuestionnairePage />} />
          <Route exact path={"/allexercises"} element={<AllExercisesPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
