// LoginPage.js

import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate();
    const [isLoginForm, setIsLoginForm] = useState(true);
    const [isRegisterForm, setIsRegisterForm] = useState(false);
    const [isForgotPasswordForm, setIsForgotPasswordForm] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        coachPassword: ''
    });

    const toLoginForm = () => {
            setIsLoginForm(true);
            setIsRegisterForm(false);
            setIsForgotPasswordForm(false);
    };

    const toRegisterForm = () => {
        setIsLoginForm(false);
        setIsRegisterForm(true);
        setIsForgotPasswordForm(false);
    };

    const toForgotPasswordForm = () => {
        setIsLoginForm(false);
        setIsRegisterForm(false);
        setIsForgotPasswordForm(true);
    };

    const handlePasswordRecovery = async () => {
        // Add your password recovery logic here, including API call
        try {
            const response = await fetch('/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                window.alert(`Password recovery failed: ${errorMessage}`);
            } else {
                navigate("/");
            }
        } catch (error) {
            console.error('Error during password recovery:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLogin = async () => {
        // Add your authentication logic here, including API call
        await authenticate("/login");

    };

    const handleRegistration = async () => {
        // Add your authentication logic here, including API call
        if (formData.password !== formData.password2) {
            window.alert('Passwörter stimmen nicht überein');
            return;
        }
        await authenticate('/register');
    };

    const authenticate = async (url) => {
        try {
            const response = await fetch('/api' + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                window.alert(`Anmeldung/Registrierung fehlgeschlagen: ${errorMessage}`);
            } else {
                navigate("/");
            }
        } catch (error) {
            console.error('Fehler bei der Anmeldung/Registrierung:', error);
        }
    }

    return (
        <div className="bg-secondary h-screen flex items-center justify-center">
            <div className="bg-dark p-8 rounded-md shadow-md w-full max-w-md">
                <h1 className="text-3xl font-bold text-primary mb-6">
                    {isLoginForm && 'Anmeldung'}
                    {isRegisterForm && 'Registrierung'}
                    {isForgotPasswordForm && 'Passwort vergessen'}
                </h1>

                <form>

                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full mb-4 p-2 rounded-md"
                    />

                    {!isForgotPasswordForm && (<input
                        type="password"
                        name="password"
                        placeholder="Passwort"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="w-full mb-4 p-2 rounded-md"
                    />
                    )}


                    {isRegisterForm && (
                        <>
                            <input
                                type="password"
                                name="password2"
                                placeholder="Passwort wiederholen"
                                value={formData.password2}
                                onChange={handleInputChange}
                                className="w-full mb-4 p-2 rounded-md"
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full mb-4 p-2 rounded-md"
                            />
                        </>
                    )}

                    {isLoginForm && (
                        <button
                            type="button"
                            onClick={handleLogin}
                            className="w-full bg-accent text-dark py-2 rounded-md"
                        >
                            Anmelden
                        </button>
                    )}

                    {isRegisterForm && (
                        <button
                            type="button"
                            onClick={handleRegistration}
                            className="w-full bg-accent text-dark py-2 rounded-md"
                        >
                            Registrieren
                        </button>
                    )}

                    {isForgotPasswordForm && (
                        <button
                            type="button"
                            onClick={handlePasswordRecovery}
                            className="w-full bg-accent text-dark py-2 rounded-md"
                        >
                            Email mit Code anfordern
                        </button>
                    )}


                </form>

                {isLoginForm && (<div>
                    <p className="text-primary mt-4 cursor-pointer" onClick={toRegisterForm}>
                        Noch kein Account? Hier registrieren
                    </p>
                    <p className="text-primary mt-4 cursor-pointer" onClick={toForgotPasswordForm}>
                        Passwort vergessen?
                    </p>
                    </div>
                )}

                {isRegisterForm && (<div>
                        <p className="text-primary mt-4 cursor-pointer" onClick={toLoginForm}>
                            Schon ein Account? Hier anmelden
                        </p>
                    </div>
                )}

                {isForgotPasswordForm && (<div>
                        <p className="text-primary mt-4 cursor-pointer" onClick={toLoginForm}>
                            Zurück zur Anmeldung
                        </p>
                    </div>
                )}


            </div>
        </div>
    );
};

export default LoginPage;
