import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

// ScheduleComponent component
const ScheduleComponent = ( {user, setUser, plan}) => {

    const [selectedDays, setSelectedDays] = useState((!user.exerciseDays || user.exerciseDays.length === 0) ? {day1 : plan.recommendedExerciseDays[0], day2 : plan.recommendedExerciseDays[1]}
        : {day1 : user.exerciseDays[0], day2 : user.exerciseDays[1]});
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();


    const handleSave = async (e) => {
        e.preventDefault();
        if (selectedDays.day1 === selectedDays.day2) {
            setErrorMessage('Bitte unterschiedliche Tage auswählen.');
            return;
        }

        try {
            const response = await fetch('/api/settings/schedule', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include http-only cookies if needed
                body: JSON.stringify({exerciseDays : [selectedDays.day1, selectedDays.day2]}),
            });
            if (!response.ok) {
                throw new Error('Failed to save schedule');
            }

            let user = await response.json();
            if (user.exerciseSelectionDone && user.setupDone && user.scheduleDone) {
                navigate("/");
                return;
            }

            setErrorMessage('');
            setUser(user);

            // Optionally, perform any other actions after successful save
        } catch (error) {
            console.error(error.message);
            setErrorMessage('Failed to save schedule');
        }
    };

    return (
          <div className="m-4">

            <h1 className="text-3xl font-bold text-secondary mb-5">Trainingsplan</h1>
            {/*
            <ul>
                {["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"]
                    .map((day) => (
                        <li key={day} className="mt-2 text-white">{day}: {plan.plannedSchedule[day].toString()}</li>
                    ))}
            </ul>
            */}
            <h2 className="text-lg font-semibold text-secondary mt-5">Deine Trainingstage</h2>
            <div className="mt-2">
                <select
                    id="day1"
                    value={selectedDays.day1}
                    onChange={(e) =>
                        setSelectedDays({...selectedDays, day1: e.target.value})
                    }
                    className="block w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-accent focus:ring focus:ring-accent-light"
                >
                    <option value="Montag">Montag</option>
                    <option value="Dienstag">Dienstag</option>
                    <option value="Mittwoch">Mittwoch</option>
                    <option value="Donnerstag">Donnerstag</option>
                    <option value="Freitag">Freitag</option>
                    <option value="Samstag">Samstag</option>
                    <option value="Sonntag">Sonntag</option>
                </select>
            </div>
            <div className="mt-2">
                <select
                    id="day2"
                    value={selectedDays.day2}
                    onChange={(e) =>
                        setSelectedDays({...selectedDays, day2: e.target.value})
                    }
                    className="block w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-accent focus:ring focus:ring-accent-light"
                >
                    <option value="Montag">Montag</option>
                    <option value="Dienstag">Dienstag</option>
                    <option value="Mittwoch">Mittwoch</option>
                    <option value="Donnerstag">Donnerstag</option>
                    <option value="Freitag">Freitag</option>
                    <option value="Samstag">Samstag</option>
                    <option value="Sonntag">Sonntag</option>
                </select>
            </div>
            <div className="text-red-500">{errorMessage}</div>
            <button className="w-full bg-accent text-dark py-3 mt-3 rounded-md" onClick={handleSave}>
                Speichern
            </button>

        </div>


    )
        ;
};

export default ScheduleComponent;
