import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCog } from '@fortawesome/free-solid-svg-icons';

const Header = () => {

    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center">
            <div className="flex items-center">
                <Link to="/" className="text-xl mr-4">
                    <FontAwesomeIcon icon={faHome} />
                </Link>
                <Link to="/" className="text-xl font-bold">
                    FSC AI
                </Link>
            </div>
            <div>
                <Link to={"/allexercises"} className={"text-l font-bold"} >Alle Übungen</Link>
            </div>
            <Link to={'/settings'} className="text-xl">
                <FontAwesomeIcon icon={faCog} />
            </Link>
        </header>
    );
};

export default Header;
