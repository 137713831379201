import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const AthleteDashboard = ({currentPlan}) => {

    const [schedule, setSchedule] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('/api/schedule', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Include http-only cookies if needed
                });

                if (response.ok) {
                    const data = await response.json();
                    setSchedule(data);
                }
            } catch (error) {
                console.error('Error during API call:', error);
            }
        };

        fetchUserData();
    }, []); // Empty dependency array ensures the effect runs only once on mount


    const startTraining = async (item) => {
        try {
            const response = await fetch('/api/workouts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include http-only cookies if needed
                body : JSON.stringify(item)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/workouts/" + data._id);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    }

    let plan = <div></div>;
    if (!currentPlan) {
        plan = <div>Loading</div>
    } else plan = (
        <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold text-secondary mb-4">Exercise Plan</h1>
            {Object.keys(currentPlan).map((key, index) => (
                <ExerciseItem key={index} name={key} {...currentPlan[key]} />
            ))}
        </div>
    );

    return <div>
        <div className="m-4">
            <h1 className="text-3xl font-bold text-secondary my-4">Trainingsplan</h1>
            {schedule.map(item => {
                let today = new Date();
                today = today.toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                let itemDate = (new Date(item.date)).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                let itemDateIsToday = today === itemDate;

                if (itemDateIsToday) {
                    return <div className='bg-secondary rounded-lg shadow-xl border hover:border-secondary p-4 mb-4 relative' key={schedule.indexOf(item)}
                                onClick={() => startTraining(item)}>
                        <h2 className="text-lg font-semibold text-accent mb-2">{item.type}</h2>
                        <p className="text-lg text-primary mb-2">Heute</p>
                        {item.deload && <p className="text-secondary">Deload</p>}
                        <span className={"absolute top-1/2 right-4 transform -translate-y-1/2 text-accent"}><FontAwesomeIcon icon={faChevronRight} /></span>
                    </div>
                }

                return <div className='bg-primary rounded-lg p-4 mb-4' key={schedule.indexOf(item)}>
                    <h2 className="text-lg font-semibold text-accent mb-2">{item.day + ", " + itemDate}</h2>

                    <h3 className="text-lg font-semibold text-secondary mb-2">{item.type}</h3>
                    {item.deload && <p className="text-accent">Deload</p>}
                </div>
            })}
        </div>
        {/*plan*/}
    </div>


}

const ExerciseItem = ({name, weight, goalReps, sets, exercise}) => {
    return (
        <div className="bg-primary rounded-lg p-4 mb-4">
            <h2 className="text-lg font-semibold text-secondary mb-2">{name}</h2>
            <h3 className="text-lg font-semibold text-secondary mb-2">{exercise}</h3>
            <p className="text-accent">Weight: {weight}</p>
            <p className="text-accent">Goal Reps: {goalReps}</p>
            <p className="text-accent">Sets: {sets}</p>
        </div>
    );
};

export default AthleteDashboard;