// NewPasswordPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NewPasswordPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        newPassword: '',
        confirmPassword: '',
        otp: '',
    });

    useEffect(() => {
        const email = new URLSearchParams(window.location.search).get('email');
        const otp = new URLSearchParams(window.location.search).get('otp');

        setFormData((prevData) => ({ ...prevData, email, otp }));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleResetPassword = async () => {
        const { email, newPassword, confirmPassword, otp } = formData;

        if (newPassword !== confirmPassword) {
            window.alert('Passwörter stimmen nicht überein');
            return;
        }

        try {
            const response = await fetch('/api/update-password', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, otp, newPassword }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                window.alert(`Fehler beim Ändern des Passworts: ${errorMessage}`);
            } else {
                window.alert('Passwort geändert');
                navigate('/login');
            }
        } catch (error) {
            console.error('Fehler beim Ändern des Passworts:', error);
        }
    };

    return (
        <div className="bg-secondary h-screen flex items-center justify-center">
            <div className="bg-dark p-8 rounded-md shadow-md w-full max-w-md">
                <h1 className="text-3xl font-bold text-primary mb-6">Passwort ändern</h1>

                <form>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        readOnly
                        className="w-full mb-4 p-2 rounded-md"
                    />
                    <input
                        type="password"
                        name="newPassword"
                        placeholder="Neues Passwort"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        className="w-full mb-4 p-2 rounded-md"
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Passwort wiederholen"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="w-full mb-4 p-2 rounded-md"
                    />
                    <input type="hidden" name="otp" value={formData.otp} />

                    <button
                        type="button"
                        onClick={handleResetPassword}
                        className="w-full bg-accent text-dark py-2 rounded-md"
                    >
                        Passwort ändern
                    </button>
                </form>
            </div>
        </div>
    );
};

export default NewPasswordPage;
