
import Header from "../Util/Header";

const AllExercisesPage = () => {

    const exercises = [{
        name: "Barbell Backsquat",
        video: "https://www.youtube.com/embed/E9-U37p1ing"
    }, {
        name: "Lunges",
        video: ""
    }, {
        name: "Boxjump",
        video: "https://www.youtube.com/embed/bzKMLmvurfI"
    }, {
        name: "Counter Movement Jump",
        video: "https://www.youtube.com/embed/AlteMweXu58"
    }, {
        name: "Romanian Deadlift",
        video: "https://www.youtube.com/embed/dBMidaBb8v8"
    }, {
        name: "Copenhagens (short lever)",
        video: "https://www.youtube.com/embed/uZ7EoE4Larc"
    }, {
        name: "Step Downs",
        video: "https://www.youtube.com/embed/tOplbmmW2Rg"
    }, {
        name: "Pogo Hops",
        video: "https://www.youtube.com/embed/JZWNJrIY8fM"
    }, {
        name: "Banded Hip Adduction",
        video: "https://www.youtube.com/embed/WcGD1V25sWY"
    }, {
        name: "Spanish Squat",
        video: "https://www.youtube.com/embed/Yr4VIVYWp10"
    }, {
        name: "Tibraises",
        video: "https://www.youtube.com/embed/pn7DLqo-IKs"
    }, {
        name: "Langhantel Bankdrücken",
        video: "https://www.youtube.com/embed/lkoYW5BNLDI"
    }, {
        name: "Kurzhantel Bankdrücken",
        video: "https://www.youtube.com/embed/9f1y7UuxNWc"
    }, {
        name: "Bankdrücken an Maschine",
        video: ""
    }, {
        name: "Langhantel Rudern",
        video: "https://www.youtube.com/embed/kSFf561rsMU"
    }, {
        name: "Helms Rows",
        video: "https://www.youtube.com/embed/h6v6P78GP8I"
    }, {
        name: "Rudern an Maschine",
        video: ""
    }, {
        name: "Langhantel Overheadpress stehend",
        video: "https://www.youtube.com/embed/673WFWDjR9Y"
    }, {
        name: "Langhantel Overheadpress sitzend",
        video: "https://www.youtube.com/embed/J523wMytZY4"
    }, {
        name: "Kurzhantel Overheadpress stehend",
        video: "https://www.youtube.com/embed/3Rr3tw6cs8I"
    }, {
        name: "Kurzhantel Overheadpress sitzend",
        video: "https://www.youtube.com/embed/fRYcsxoFRVE"
    }, {
        name: "Overheadpress an Maschine",
        video: ""
    }, {
        name: "Lat Pulldown",
        video: "https://youtube.com/embed/CAwf7n6Luuc?&start=53"
    }, {
        name: "Klimmzüge",
        video: "https://www.youtube.com/embed/X7yUEPC_2qs"
    }, {
        name: "Pallof Press",
        video: "https://www.youtube.com/embed/rf8Crw5_cHc"
    }

    ];

    return (
        <div className="bg-gray-100 min-h-screen">
            <Header/>
            <div className="max-w-screen-lg mx-auto py-8">
                {exercises.map(exercise => (
                    <div key={exercise.name} className="p-5 bg-gray-300 mb-10" >
                        <h2 className="text-xl font-bold mb-2">{exercise.name}</h2>
                        {exercise.video !== "" && (

                                <div className="aspect-video">
                                    <iframe
                                        src={exercise.video}
                                        title={exercise.name}
                                        className={"w-full h-full"}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>

                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );

};

export default AllExercisesPage;